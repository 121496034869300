import { Button, Icon, Modal } from '@acciona/ui-ionic-kit';
import { IonContent, IonFooter, IonLabel, IonTitle, IonToolbar } from '@ionic/react';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IIcon } from '../../../../_api/services/generalServices/types';
import { FileInput } from '../../../../components/FileInput/FileInput';
import { TextInput } from '../../../../components/TextInput/TextInput';
import styles from './styles.module.scss';

type Props = {
	showModal: boolean;
	onClose: () => void;
	saveChanges: (state: IIcon) => void;
	setSuccess?: any;
	setError?: any;
	hasWritePermission: boolean;
};
const emptyIcon: IIcon = { id: -1, name: '', url: '', svgName: '' };
export const AddIconModal: React.FC<Props> = ({
	showModal,
	onClose,
	saveChanges,
	setSuccess,
	setError,
	hasWritePermission,
}) => {
	const { t } = useTranslation();
	const [icon, setIcon] = useState<IIcon>(emptyIcon);
	const [canSave, setCanSave] = useState(false);

	//TODO : literal de errores, contador para la id negativa
	useEffect(() => {
		setCanSave(icon?.name.trim() !== '' && icon?.svgName.trim() !== '');
	}, [icon]);
	const handleNameChange = (e) => {
		const newName = e.target.value;
		setIcon((prevValues) => {
			const nextValues = _.cloneDeep(prevValues);
			return {
				...nextValues,
				name: newName,
			};
		});
	};

	const handleFileChange = (event) => {
		const file = event.target.files[0];
		if (file && file.type === 'image/svg+xml') {
			const reader = new FileReader();
			reader.onload = (e) => {
				const parser = new DOMParser();
				const svgDoc = parser.parseFromString(e.target.result as string, 'image/svg+xml');
				const svgElement = svgDoc.querySelector('svg');
				//check svg width and height
				const width = parseFloat(svgElement.getAttribute('width'));
				const height = parseFloat(svgElement.getAttribute('height'));
				if (width > 24 || height > 24) {
					setError(t('icon_resolution_error'));
					return;
				}
				const newSvg = e.target.result;
				setIcon((prevValues) => {
					const nextValues = _.cloneDeep(prevValues);
					return {
						...nextValues,
						id: new Date().getTime(),
						svgContent: newSvg,
						svgName: file.name,
					};
				});
			};
			reader.readAsText(file);
		} else {
			setError(t('icon_type_error'));
		}
	};
	const resetModal = () => {
		setIcon(emptyIcon);
		onClose();
	};
	const handleSave = () => {
		saveChanges(icon);
		setSuccess(t('toast_icon_add_success'));
		resetModal();
	};
	return (
		<Modal
			isOpen={showModal}
			onDidDismiss={() => {
				resetModal();
			}}
			className={styles.modal}
		>
			<IonToolbar>
				<Button slot="end" fill={'clear'} color="light" className="btnCloseModal" onClick={onClose}>
					<IonLabel className="dark">
						<Icon color="primary" className="icon icon-close icon20"></Icon>
					</IonLabel>
				</Button>
				<IonTitle>
					<h3 className={`ion-text-center ${styles.headline}`}>
						<b>{`${t('btn_add_icon')}`}</b>
					</h3>
				</IonTitle>
			</IonToolbar>
			<IonContent>
				<p className={styles.footnote}>{t('add_icon_modal_desc')}</p>
				<div className={styles.inputsContainer}>
					<FileInput
						title={t('icon')}
						subtitle={t('max_icon_file_values')}
						fileName={icon.svgName}
						disabled={!hasWritePermission}
						onChange={handleFileChange}
						inputOptions={{
							accept: '.svg',
						}}
					/>
					<TextInput
						name="iconName"
						className="ion-text-end"
						onIonInput={handleNameChange}
						value={icon.name}
						maxlength={32}
						placeholder={t('icon_name')}
						aria-label={t('icon_name')}
						label={t('icon_name')}
					></TextInput>
					{/* {icon.svgContent && <div dangerouslySetInnerHTML={{ __html: icon.svgContent }} />} */}
				</div>
			</IonContent>
			<IonFooter className={styles.btnModal}>
				<div>
					<Button
						className={`${styles.btnHeader} ${styles.secondaryBtn}`}
						onClick={() => {
							onClose();
						}}
					>
						{t('btn_cancel')}
					</Button>
				</div>
				<div>
					<Button color="primary" className={styles.btnHeader} disabled={!canSave} onClick={handleSave}>
						{t('btn_accept')}
					</Button>
				</div>
			</IonFooter>
		</Modal>
	);
};
