import _ from 'lodash';
import { LANGUAGES } from '../../../../utils/constants';
import { ServiceConfig, ServiceDetail } from '../../../../_api/services/generalServices/types';

export const fillEmptyTranslations = (translations: any) => {
	if (_.isEmpty(translations.name) || _.isEmpty(translations.content)) {
		return { name: [], content: [] };
	}
	translations.name.forEach((t) => {
		if (!t.translation) {
			t.translation = translations.name.find((t) => t.translation)?.translation || '';
		}
	});
	translations.content.forEach((t) => {
		if (!t.translation) {
			t.translation = translations.content.find((t) => t.translation)?.translation || '';
		}
	});
	return translations;
};

/**
 * @param {ServiceDetail} item
 * @returns {boolean} true if shuttle has empty fields or false if it hasn't
 */
export const checkEmptyFields = (item: ServiceDetail) => {
	// no translations stored or unsetted
	if (item.action !== 'delete' && _.isEmpty(item?.translationsName || _.isEmpty(item?.translationsContent))) {
		return true;
	}

	const acceptedLanguagesTranslations = {
		name: item.translationsName?.filter((t) => LANGUAGES.includes(t.language)),
		content: item.translationsContent?.filter((t) => LANGUAGES.includes(t.language)),
	};
	const translationsToCheck = fillEmptyTranslations(acceptedLanguagesTranslations);

	const hasEmptyFields = (t) => {
		return !t.translation || _.isEmpty(t.translation) || t.translation === '' || t.translation === '<p><br></p>';
	};

	if (item.action === 'create') {
		return (
			translationsToCheck.name.every((translation) => hasEmptyFields(translation)) ||
			translationsToCheck.content.every((translation) => hasEmptyFields(translation))
		);
	} else if (item.action === 'update') {
		return (
			translationsToCheck.name.some((translation) => hasEmptyFields(translation)) ||
			translationsToCheck.content.some((translation) => hasEmptyFields(translation))
		);
	}

	return false;
};

/**
 * @param {ServiceConfig} items
 * @returns {boolean} true if none shuttle has empty fields or false if almost one shuttle has empty fields
 */
export const validateData = (data: ServiceConfig): boolean => {
	return _.cloneDeep(data).details.every((item) => {
		const check = checkEmptyFields(item);
		return !check;
	});
};

export const getDefaultItemName = (initialData: ServiceConfig, item: ServiceDetail, selectedLang: string) => {
	const defaultData = initialData.details.find((e) => e.id === item.id);
	return defaultData?.translationsName?.find((e) => e.language === selectedLang)?.translation || '';
};
