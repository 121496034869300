import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Switch } from 'react-router';
import { Route } from 'react-router-dom';
import { history } from '../_helpers/history';
import SplitPane from '../components/SplitPane/SplitPane';
import Can from '../hoc/Can/Can';
import PrivateRoute from '../hoc/PrivateRoute/PrivateRoute';
import AppPermissionsPage from '../pages/AppPermissions/AppPermissionsPage';
import Callback from '../pages/Auth/Callback';
import Login from '../pages/Auth/Login';
import Communications from '../pages/Communications';
import { IconsTransversal } from '../pages/IconsTranversal/IconsTransversal';
import Incidents from '../pages/Incidents/Incidents';
import Parking from '../pages/Parking/Parking';
import RolesPortal from '../pages/RolesPortal/RolesPortal';
import RolesTransversal from '../pages/RolesTransversal/RolesTransversal';
import Rooms from '../pages/Rooms';
import ConfigureService from '../pages/Services/ConfigureService/ConfigureService';
import Services from '../pages/Services/Services';
import ServicesTransversal from '../pages/ServicesTransversal/ServicesTransversal';
import Terms from '../pages/Terms';
import Transversal from '../pages/Transversal';
import Workstations from '../pages/Workstations/Workstations';
import { FUNCTIONALITIES } from '../utils/constants';

export const Routes = () => {
	return (
		<IonReactRouter history={history}>
			<Switch>
				<Route exact path="/login">
					<Login />
				</Route>
				<Route exact path="/callback">
					<Callback />
				</Route>
				<SplitPane>
					<PrivateRoute exact path="/incidents">
						<Can functionality={FUNCTIONALITIES.incidents} action="READ">
							<Incidents />
						</Can>
					</PrivateRoute>
					<PrivateRoute exact path="/communications">
						<Can functionality={FUNCTIONALITIES.communications} action="READ">
							<Communications />
						</Can>
					</PrivateRoute>
					<PrivateRoute exact path="/rooms">
						<Can functionality={FUNCTIONALITIES.room} action="READ">
							<Rooms />
						</Can>
					</PrivateRoute>
					<PrivateRoute exact path="/workstations">
						<Can functionality={FUNCTIONALITIES.desk} action="READ">
							<Workstations />
						</Can>
					</PrivateRoute>
					<PrivateRoute exact path="/parking">
						<Can functionality={FUNCTIONALITIES.parking} action="READ">
							<Parking />
						</Can>
					</PrivateRoute>
					<PrivateRoute exact path="/terms">
						<Can functionality={FUNCTIONALITIES.transversal} action="READ">
							<Terms />
						</Can>
					</PrivateRoute>
					<PrivateRoute exact path="/services/settings">
						<Can functionality={FUNCTIONALITIES.services} action="READ">
							<Services />
						</Can>
					</PrivateRoute>
					<PrivateRoute exact path="/services/mobility">
						<Can functionality={FUNCTIONALITIES.services} action="READ">
							<ConfigureService
								serviceId={8}
								service="shuttles"
								title={'menu_mobility'}
								tabString={'tab_shuttles'}
								translations={{
									infoMsg: 'shuttle_text_header',
									itemKey: 'shuttle',
									itemLabel: 'name_shuttle',
									itemPlaceholder: 'example_shuttle',
									addItemLbl: 'add_shuttle',
									deleteItemLbl: 'delete_shuttle',
									confirmDeleteLbl: 'confirm_delete_shuttle',
								}}
							/>
						</Can>
					</PrivateRoute>
					<PrivateRoute exact path="/services/restaurants">
						<Can functionality={FUNCTIONALITIES.services} action="READ">
							<ConfigureService
								serviceId={1}
								service="restaurants"
								title={'menu_restaurants'}
								tabString={'tab_restaurants'}
								translations={{
									infoMsg: 'restaurant_text_header',
									itemKey: 'restaurant',
									itemLabel: 'name_restaurant',
									itemPlaceholder: 'example_restaurant',
									addItemLbl: 'add_restaurant',
									deleteItemLbl: 'delete_restaurant',
									confirmDeleteLbl: 'confirm_delete_restaurant',
								}}
							/>
						</Can>
					</PrivateRoute>
					<PrivateRoute exact path="/roles-portal">
						<Can functionality={FUNCTIONALITIES.admin} action="READ">
							<RolesPortal />
						</Can>
					</PrivateRoute>
					<PrivateRoute exact path="/app-permissions">
						<Can functionality={FUNCTIONALITIES.admin} action="READ">
							<AppPermissionsPage />
						</Can>
					</PrivateRoute>
					<PrivateRoute exact path="/transversal-configurations">
						<Can functionality={FUNCTIONALITIES.transversal} action="READ">
							<Transversal />
						</Can>
					</PrivateRoute>
					<PrivateRoute exact path="/transversal/services">
						<Can functionality={FUNCTIONALITIES.superadmin} action="READ">
							<ServicesTransversal />
						</Can>
					</PrivateRoute>
					<PrivateRoute exact path="/transversal/roles">
						<Can functionality={FUNCTIONALITIES.superadmin} action="READ">
							<RolesTransversal />
						</Can>
					</PrivateRoute>
					<PrivateRoute exact path="/transversal/icons">
						<Can functionality={FUNCTIONALITIES.superadmin} action="READ">
							<IconsTransversal />
						</Can>
					</PrivateRoute>
					<Route exact path="/blank">
						<></>
					</Route>
					<Route>
						<Redirect to="/login" />
					</Route>
				</SplitPane>
			</Switch>
		</IonReactRouter>
	);
};
