import { SegmentButton, Segmented, Toast } from '@acciona/ui-ionic-kit';
import { IonContent, IonLabel } from '@ionic/react';
import { useEffect, useRef, useState } from 'react';
import 'react-quill/dist/quill.snow.css';
import { HeaderPage } from '../../../components/HeaderPage/HeaderPage';
import useAppContext from '../../../hooks/useAppContext';
import { usePermissions } from '../../../hooks/usePermissions';
import { useScrollBar } from '../../../hooks/useScrollBar';

import { FUNCTIONALITIES } from '../../../utils/constants';
import EditServiceConfig from './components/EditServiceConfig';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';

type Props = { service?: string; serviceId?: number; title?: string; tabString?: string; translations: any };

export const ConfigureService: React.FC<Props> = ({ serviceId, service, title, tabString, translations }) => {
	const { t } = useTranslation();
	const { setThereAreUnsavedChanges } = useAppContext();
	const [segmentedSelected, setSegmentedSelected] = useState(service);
	const [error, setError] = useState(null);
	const [success, setSuccess] = useState(null);
	const [scroll, setScroll] = useState(false);
	let footerStyle = null;
	const refContainer = useRef(null);
	const refHeader = useRef(null);
	const hasScroll = useScrollBar(refContainer, refHeader, segmentedSelected);
	footerStyle = hasScroll ? styles.hasScroll : styles.noScroll;
	const { permissions, error: errorPermissions } = usePermissions(FUNCTIONALITIES.services, 'WRITE');

	useEffect(() => {
		setError(errorPermissions);
	}, [errorPermissions]);

	useEffect(() => {
		return () => {
			setThereAreUnsavedChanges(false);
		};
	}, []);

	return (
		<IonContent scrollEvents forceOverscroll={true} onIonScroll={(x) => setScroll(x.detail.scrollTop > 0)}>
			<HeaderPage scroll={scroll} title={t(title)}>
				<div ref={refHeader}>
					<Segmented value={segmentedSelected}>
						<SegmentButton onClick={() => setSegmentedSelected(service)} value={service} mode={'md'}>
							<IonLabel>{t(tabString)}</IonLabel>
						</SegmentButton>
					</Segmented>
				</div>
			</HeaderPage>
			<div className="container" ref={refContainer}>
				<div className={segmentedSelected === service ? styles.display : styles.hide}>
					<EditServiceConfig
						serviceId={serviceId}
						setError={setError}
						setSuccess={setSuccess}
						hasWritePermission={permissions.WRITE}
						footerStyle={footerStyle}
						translations={translations}
					/>
				</div>
			</div>
			<Toast isOpen={!!error} message={error} onDidDismiss={() => setError('')} position="bottom" type="error" />
			<Toast
				isOpen={!!success}
				message={success}
				onDidDismiss={() => setSuccess('')}
				position="bottom"
				type="success"
			/>
		</IonContent>
	);
};

export default ConfigureService;
