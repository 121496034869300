import { Alert, Button, Icon } from '@acciona/ui-ionic-kit';
import { IonButton, IonLabel, IonLoading } from '@ionic/react';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import ReactQuill from 'react-quill';
import { generalServicesService } from '../../../../_api/services/generalServices';
import { ServiceConfig } from '../../../../_api/services/generalServices/types';
import { LanguageSelector } from '../../../../components/LanguageSelector/LanguageSelector';
import { TextInput } from '../../../../components/TextInput/TextInput';
import useAppContext from '../../../../hooks/useAppContext';
import { DEFAULT_REACT_QUILL_EDITOR_CONFIG, LANGUAGES } from '../../../../utils/constants';
import { zeroPad } from '../../../../utils/functions';
import { getDefaultItemName, validateData } from './helpers';
import styles from './styles.module.scss';

export type Props = {
	serviceId: number;
	hasWritePermission: boolean;
	setError: (errorMessage: string) => void;
	setSuccess: (errorMessage: string) => void;
	footerStyle: string;
	translations: {
		infoMsg: string;
		itemKey: string;
		itemLabel: string;
		itemPlaceholder: string;
		addItemLbl: string;
		deleteItemLbl: string;
		confirmDeleteLbl: string;
	};
};

export const EditServiceConfig: React.FC<Props> = ({
	serviceId,
	hasWritePermission,
	setError,
	setSuccess,
	footerStyle,
	translations,
}) => {
	const { t } = useTranslation();
	const queryClient = useQueryClient();
	const { setThereAreUnsavedChanges } = useAppContext();
	const [selectedLang, setSelectedLang] = useState('es');
	const [itemId, setItemId] = useState(0);
	const [showAlertDelete, setShowAlertDelete] = useState(false);
	const [changeQuill, setChangeQuill] = useState(false);
	const [data, setData] = useState<ServiceConfig>({
		id: 0,
		images: { backgroundDesktop: '', backgroundMobile: '' },
		titleTranslations: [],
		subtitleTranslations: [],
		details: [],
	});
	const [isEdited, setIsEdited] = useState(false);

	useEffect(() => {
		setThereAreUnsavedChanges(isEdited);
	}, [isEdited]);

	const { data: initialData, isLoading: isLoading } = useQuery<ServiceConfig>(
		['data'],
		async () => await generalServicesService.getServiceDetailedConfig(serviceId),
		{
			refetchOnWindowFocus: false,
			onError: (error) => setError(error as string),
			onSuccess: (data) => {
				setData(data);
			},
		},
	);

	const { mutate: handleSave, isLoading: loadingSave } = useMutation(
		generalServicesService.saveServiceDetailedConfig,
		{
			onSuccess: () => {
				setSuccess(t('msg_success'));
				setIsEdited(false);
				queryClient.refetchQueries('data');
			},
			onError: (error) => {
				setError(error as string);
			},
		},
	);

	const handleDelete = (id: number) => {
		setItemId(id);
		setShowAlertDelete(true);
	};

	const deleteItem = () => {
		setData((oldState) => {
			const newState = _.cloneDeep(oldState);
			const itemToDelete = newState.details.find((i) => i.id === itemId);
			if (!itemToDelete) return oldState;
			itemToDelete.action = 'delete';
			const isInInitialData = initialData.details.find((i) => i.id === itemId);
			if (!isInInitialData) {
				newState.details = newState.details.filter((i) => i.id !== itemId);
			}
			return newState;
		});
		setIsEdited(true);
	};

	const changeEditItem = (id, key, value) => {
		const selectedItem = data.details.find((i) => i.id === id);
		if (selectedItem?.[key]?.find((e) => e.language == selectedLang)?.translation !== value) {
			if (key === 'translationsContent' && !changeQuill) {
				return;
			}
			setIsEdited(true);

			const item = data.details.find((o) => o.id === id);
			const itemEdited = {
				...item,
				action: !item.action ? 'update' : item.action,
			};

			itemEdited[key].find((e) => e.language == selectedLang).translation = value;

			setData((oldState) => {
				const newState = _.cloneDeep(oldState);
				newState.details = [..._.filter(newState.details, (o) => o.id !== id), itemEdited];
				return newState;
			});
		}
	};

	const handleAddItem = () => {
		if (!validateData(data)) {
			setError(t('msg_error_all_fields_required'));
			return;
		}

		setData((oldState) => {
			const newState = _.cloneDeep(oldState);
			newState.details = [
				...newState.details,
				{
					id: new Date().getTime(),
					action: 'create',
					enabled: true,
					translationsName: LANGUAGES.map((lang) => ({ language: lang, translation: '' })),
					translationsContent: LANGUAGES.map((lang) => ({ language: lang, translation: '' })),
					default: zeroPad(oldState.details.length + 1, 2),
				},
			];
			return newState;
		});
	};

	const handleSaveData = () => {
		if (!validateData(data)) {
			setError(t('msg_error_all_fields_required'));
			return;
		}
		const saveData = _.cloneDeep(data);
		// Filter and fill details
		saveData.details = saveData.details
			?.filter((e) => e.action === 'update' || e.action === 'create' || e.action === 'delete')
			.map((e) => {
				e.translationsName?.forEach((t) => {
					if (_.isEmpty(t.translation)) {
						t.translation = e.translationsName.find((s) => !_.isEmpty(s.translation))?.translation;
					}
				});
				e.translationsContent?.forEach((t) => {
					if (_.isEmpty(t.translation)) {
						t.translation = e.translationsContent.find((s) => !_.isEmpty(s.translation))?.translation;
					}
				});
				return e;
			});
		handleSave(saveData);
	};

	return (
		<>
			<LanguageSelector selectedLang={selectedLang} setSelectedLang={setSelectedLang} />
			<p className={styles.footnote}>{t(translations.infoMsg)}</p>
			{_.sortBy(
				data?.details?.filter((s) => s.action !== 'delete'),
				'id',
			).map((item) => {
				const name = item?.translationsName?.find((e) => e.language == selectedLang).translation ?? '';
				const content = item?.translationsContent?.find((e) => e.language == selectedLang).translation ?? '';
				const defaultName = getDefaultItemName(initialData, item, selectedLang);
				return (
					<div key={item.id}>
						<div className={styles.h2}>
							<div>{`${t(translations.itemKey)} ${name}`}</div>
							<div>
								{hasWritePermission && (
									<IonButton
										fill="clear"
										onClick={() => handleDelete(item.id)}
										className={styles.buttonDelete}
										slot="end"
									>
										<Icon className={`icon icon-delete icon24`} />
									</IonButton>
								)}
							</div>
						</div>
						<TextInput
							name={name}
							className={`ion-text-end`}
							wrapperClass={`${item.name === '' ? 'borderAlert' : ''}`}
							value={name}
							defaultValue={defaultName}
							onIonChange={(e) => {
								changeEditItem(item.id, 'translationsName', e.detail.value);
							}}
							placeholder={t(translations.itemPlaceholder)}
							disabled={!hasWritePermission}
							aria-label={t(translations.itemLabel)}
							label={t(translations.itemLabel)}
						/>
						<ReactQuill
							id={`content-${item.id}`}
							theme="snow"
							modules={DEFAULT_REACT_QUILL_EDITOR_CONFIG}
							className={styles.content}
							value={content} // TODO: Join in one property
							onChange={(e) => {
								changeEditItem(item.id, 'translationsContent', e);
							}}
							onFocus={() => setChangeQuill(true)}
							onBlur={() => setChangeQuill(false)}
							readOnly={!hasWritePermission}
							// preserveWhitespace={true}
						/>
					</div>
				);
			})}
			<div className={styles.separator}></div>
			{hasWritePermission && (
				<>
					<Button slot="start" className={`${styles.btnSave} ${styles.secondaryBtn}`} onClick={handleAddItem}>
						<Icon className="icon icon-plus iconPlus" />
						<IonLabel>{t(translations.addItemLbl)}</IonLabel>
					</Button>
					<div className={`${footerStyle} ${styles.footerButton}`}>
						<Button disabled={!isEdited} color="primary" className={styles.btnHeader} onClick={handleSaveData}>
							{t('btn_save_data')}
						</Button>
					</div>
				</>
			)}

			<Alert
				isOpen={showAlertDelete}
				onDidDismiss={() => setShowAlertDelete(false)}
				header={t(translations.deleteItemLbl)}
				message={t(translations.confirmDeleteLbl)}
				buttons={[
					{ text: 'No', role: 'cancel' },
					{ text: t('lbl_affirmative'), handler: () => deleteItem() },
				]}
				mode="ios"
			/>
			<IonLoading isOpen={isLoading || loadingSave} message={t('msg_loading')} duration={10000} />
		</>
	);
};

export default EditServiceConfig;
