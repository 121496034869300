import { Button, Icon } from '@acciona/ui-ionic-kit';
import { IonContent, IonLabel, IonTitle, IonToolbar } from '@ionic/react';

import { useTranslation } from 'react-i18next';
import { ConfigType, MenuItem } from '../../../../_api/services/generalServices/types';
import { useEffect, useState } from 'react';
import { LanguageSelector } from '../../../../components/LanguageSelector/LanguageSelector';

import styles from './styles.module.scss';
import PageModal from '../../../../components/PageModal/PageModal';
import ExternalAccess from './ExternalAccess';

import { generalServicesService } from '../../../../_api/services/generalServices';
import {
	checkChanges,
	checkInitialServiceConfig,
	checkValidServiceConfig,
	FILE_SIZE,
	getSelectedConfig,
	ServiceEndIcons,
} from '../helpers';

type Props = {
	service: MenuItem;
	isOpen: boolean;
	handleClose: () => void;
	setError: Function;
	setSuccess: Function;
	setThereAreUnsavedChanges?: Function;
};

const SettingsEditModal: React.FC<Props> = ({
	service,
	isOpen,
	handleClose,
	setError,
	setSuccess,
	setThereAreUnsavedChanges,
}) => {
	const { t } = useTranslation();
	const [lang, setLang] = useState('es');
	const [serviceMod, setServiceMod] = useState(structuredClone(service));
	const [canSave, setCanSave] = useState(false);
	const [isSaving, setIsSaving] = useState(false);

	const serviceTitle = service?.translations.find((t) => t.language === lang)?.text;

	const handleChangeServiceConfig = (config: ConfigType) => {
		setServiceMod((oldState) => {
			const newState = structuredClone(oldState);
			newState.translations.forEach((t) => {
				t.externalAppLink.active = false;
				t.externalLink.active = false;
				t.file.active = false;
				t.internalLink.active = false;
				t[config].active = true;
			});

			if ([ConfigType.EXTERNAL_APP_LINK, ConfigType.EXTERNAL_LINK].includes(config)) {
				newState.endIcon = ServiceEndIcons.EXTERN;
			} else if ([ConfigType.INTERNAL_LINK].includes(config)) {
				newState.endIcon = ServiceEndIcons.INTERN;
			} else if ([ConfigType.FILE].includes(config)) {
				newState.endIcon = ServiceEndIcons.FILE;
			}

			return newState;
		});
	};

	const generateFormData = (fileName: string, file: any, lang: string) => {
		const formData = new FormData();
		formData.set('fileName', fileName);
		formData.set('file', file, fileName);
		formData.set('id', service.id.toString());
		formData.set('language', lang);
		return formData;
	};

	const handleChangeServiceValue = async (key: string, value: any, selectedConfig: ConfigType) => {
		if (key === 'fileName') {
			const form = value.target;
			if (form.files.length === 0) {
				return;
			}
			if (form.files[0].size > FILE_SIZE['10MB']) {
				setError(t('file_size_invalid'));
				return;
			}
		}

		setServiceMod((oldState) => {
			const newState = structuredClone(oldState);

			const isAllEmtpy = newState.translations.every((t) => {
				return !t[selectedConfig][key] || t[selectedConfig][key] === '';
			});

			if (key === 'fileName') {
				const fileName = value.target.value.split('\\').at(-1);
				const file = value.target.files[0];
				newState.translations.forEach((t) => {
					if (isAllEmtpy || t.language === lang) {
						t.file.fileName = fileName;
						t.file.file = file;
					}
				});
			} else {
				newState.translations.forEach((t) => {
					if (
						isAllEmtpy ||
						t.language === lang ||
						selectedConfig === ConfigType.INTERNAL_LINK ||
						(selectedConfig === ConfigType.EXTERNAL_LINK && key === 'inAppBrowser')
					) {
						t[selectedConfig][key] = value;
					}
				});
			}

			return newState;
		});
	};

	const handleSaveFiles = async () => {
		const serviceWithFiles = structuredClone(serviceMod);
		for (const tr of serviceWithFiles.translations) {
			if (tr.file.file) {
				const formData = generateFormData(tr.file.fileName, tr.file.file, tr.language);
				const url = await generalServicesService.uploadFile(formData);
				tr.file.url = url;
				delete tr.file.file;
			}
		}
		return serviceWithFiles;
	};

	const handleSave = async () => {
		setIsSaving(true);
		try {
			const finalServiceConfig = await handleSaveFiles();
			await generalServicesService.saveUniqueServiceConfig(finalServiceConfig);
			setSuccess(t('msg_success'));
			handleClose();
		} catch (err) {
			setError(err);
		} finally {
			setIsSaving(false);
		}
	};

	useEffect(() => {
		if (!service) return;
		setServiceMod(checkInitialServiceConfig(structuredClone(service)));
	}, [service]);

	useEffect(() => {
		const areEqual = checkChanges(serviceMod, service);
		const isValid = checkValidServiceConfig(serviceMod);
		setThereAreUnsavedChanges(!areEqual);
		setCanSave(!areEqual && isValid);
	}, [service, serviceMod]);

	return (
		<PageModal isOpen={isOpen} handleClose={handleClose}>
			<IonToolbar className={styles.serviceModalToolbar}>
				<Button slot="start" fill={'clear'} color="light" className={styles.closeModalBtn} onClick={handleClose}>
					<IonLabel className="dark">
						<Icon className="icon icon24 icon-chevron-left"></Icon>
					</IonLabel>
				</Button>
				<IonTitle className={`centerTitle ${styles.title}`}>
					<h3>
						<b>{t(`lbl_configure_service`, { name: serviceTitle })}</b>
					</h3>
				</IonTitle>
			</IonToolbar>
			<IonContent className={styles.serviceSettingsModalBody}>
				{/* <IonItem lines="full" className={styles.configTypeRow}>
					<div className={styles.configTypeMsg}>
						{t('lbl_configuration_type')} <Icon className="icon icon-info" />
					</div>
				</IonItem> */}
				<div className={styles.container}>
					<LanguageSelector selectedLang={lang} setSelectedLang={setLang} />
					<ExternalAccess
						serviceTitle={serviceTitle}
						serviceConfig={serviceMod?.translations.find((t) => t.language === lang)}
						selectedConfig={getSelectedConfig(serviceMod, lang)}
						handleChangeServiceConfig={handleChangeServiceConfig}
						handleChangeServiceValue={handleChangeServiceValue}
					/>
				</div>
			</IonContent>
			{
				<div className={`${styles.hasScroll} ${styles.footerButton}`}>
					<Button
						disabled={!canSave || isSaving}
						onClick={handleSave}
						className={styles.btnHeader}
						color="primary"
					>
						{t('btn_save_data')}
					</Button>
				</div>
			}
		</PageModal>
	);
};

export default SettingsEditModal;
